<template>
  <div class="Enter-box">
    <p class="Enterprise-p"> <!-- <i></i> --> <span>云盘 / 续费</span></p>
    <div class="Enterprise">
      <!-- <div class="Enterprise-title">
        <span></span>
        <p>企业信息</p>
      </div> -->
      <div class="Enterprise-confirm">
        <div class="Enterprise-state">
          <span>确定订单</span>
          <span>支付</span>
          <span>支付完成</span>
        </div>
        <p><span>企业名称：</span><span>{{ this.ent_name}}</span></p>
        <!-- <p><span>统一社会信用代码：</span><span>911101083678KJ876L</span></p>
        <p><span>法人姓名：</span><span>胡佳怡</span></p>
        <p><span>法人手机号：</span><span>18900557788</span></p> -->

        <div class="Enterprise-confirm-type Enterprise-confirm-type_001">
          <span>续费类型：</span>
          <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="年费" name="first">
              <div></div>
            </el-tab-pane>
          </el-tabs> -->
          <el-radio v-model="radio" v-show="flag_buy!=0" label="1">首次购买</el-radio>
          <el-radio v-model="radio" v-show="flag_buy==0"   label="2">扩容</el-radio>
          <el-radio v-model="radio" v-show="flag_buy==0" label="3">续费</el-radio>
          <div>

          </div>


        </div>
        <div class="Enterprise-confirm-deadline">
          <p>购买期限：</p>
          <el-input-number
            v-model="num"
            controls-position="right"
            @change="handleChange2"
            :min="1"
            :max="10"
            :disabled="radio==2"
          ></el-input-number>
          <span>年</span>
        </div>
        <div class="Enterprise-confirm-deadline">
          <p>购买内存：</p>
          <el-input-number
            v-model="numS"
            controls-position="right"
            @change="handleChange3"
            :min="1"
            :max="100"
            :disabled="radio==3"
          ></el-input-number>
          <span>X 100G</span>
        </div>
        <div class="Enterprise-money">
          <p>合计金额：</p>
          <span>￥{{ money }}</span>
        </div>
        <div class="nextStep">
          <a @click="handle_to" >下一步</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {if_first_buy_disk, cal_buy_net_disk_fee, query_ent_detail } from '../../api/store'


export default {
  data() {
    return {
      activeName: 'first',
      num: 1,  // 购买年
      numS: 1, // 购买容量
      money: 13000,

      flag_buy:"",

      radio:"",

      ent_name:""
    }
  },
  mounted(){
    console.log( '111111111111', this.$store.state.ent_info);
    this.if_first_buy_disk()
    this.query_ent_detail()
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    handleChange2(value) {
      console.log(value)
      this.cal_buy_net_disk_fee()

    },
    handleChange3(value) {
      console.log(value)
      this.cal_buy_net_disk_fee()

    },


    query_ent_detail(){
      query_ent_detail({
        data:{
          ent_id:this.$store.state.ent_id
        }
      }).then((res)=>{
             console.log(res.body.data.ent_name,'企业名称')
             this.ent_name = res.body.data.ent_name

      })
    },

    if_first_buy_disk(){
      if_first_buy_disk({
        data:{
          ent_id:this.$store.state.ent_id
        }
      }).then((res)=>{
        console.log(res);
        if (res.code == 200) {
          this.flag_buy == res.body.data.flag_buy
          if (res.body.data.flag_buy == '0') {
            this.radio = '1'
          }
          this.cal_buy_net_disk_fee()
        }
      })
    },

    cal_buy_net_disk_fee(){
      let num = this.num
      let data = {
        data:{
          ent_id:this.$store.state.ent_id,
          buy_type:this.radio,
          size_net:(this.numS*1).toString(),
          years: num.toString(),
        }
      }
      console.log(data);
      cal_buy_net_disk_fee(data).then((res)=>{
        console.log('金额计算',res);
        if (res.code == 200) {
          console.log();
          this.money = res.body.data.pay_amt

        }
      })
    },

    handle_to(){
      let num = this.num
      let numS = this.numS
      let money = this.money
      let order_type = this.radio
      let order_desc=''

      if (this.radio == '1') {
        order_desc ='首次购买'
      }else if(this.radio == '2'){
        order_desc ='扩容'
      }else if(this.radio == '3 '){
        order_desc ='续费'
      }

      let itemobj = {
        num:num,
        numS:numS,
        pay_amt:money,
        order_type:order_type,
        order_desc:order_desc,
      }

      let item = JSON.stringify(itemobj)

      this.$router.push({name:'Clickyunpanfuuan',
          params :{
            item:item
          }
      })
    },


  }
}
</script>

<style scoed lang="scss">
.el-main {

  background: #f6f6fa;
}

.Enterprise-p {
  font-size: 18px;
  color: #999999;
  display: flex;
  margin: 26px 0 30px 0;
  span {
    margin: 4px;
  }
  i {
    background: url('../../assets/img/din.png') no-repeat;
    width: 23px;
    height: 30px;
    display: block;
    margin-right: 11px;
  }
}
.Enterprise {
  width: 100%;
  background: #fefefe;
  -moz-box-shadow: 0px 0px 10px #e8e8eb;
  -webkit-box-shadow: 0px 0px 10px #e8e8eb;
  box-shadow: 0px 0px 10px #e8e8eb;
  .Enterprise-confirm {
    padding: 22px 34px 51px;
    box-sizing: border-box;
    .Enterprise-state {
      background: url('../../assets/img/quedin.png');
      width: 1054px;
      height: 34px;
      display: block;
      margin: 0 auto 44px auto;
      display: flex;
      span {
        line-height: 34px;
        color: #fff;
        font-size: 14px;
        width: 33%;
        text-align: center;
      }
    }
    .Enterprise-confirm-type {
      display: flex;
      margin-bottom: 44px;
      span {
        font-size: 16px;
        color: #333333;
        margin: 10px 32px 0 0;
      }
    }
    .Enterprise-confirm-deadline {
      display: flex;
      margin-bottom: 44px;
      & > p {
        font-size: 16px;
        color: #333333;
        margin: 4px 32px 0 0;
      }
      & > span {
        font-size: 16px;
        color: #333333;
        margin: 10px 0 0 11px;
      }
    }
    .Enterprise-money {
      display: flex;
      p {
        font-size: 16px;
        color: #333333;
        margin: 0 32px 0 0;
      }
      span {
        font-size: 21px;
        color: #333333;
        margin-top: 4px;
      }
    }
    .nextStep {
      a {
        width: 100px;
        height: 45px;
        background: #fc4453;
        border-radius: 10px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        line-height: 45px;
        margin: 0 auto;
        display: block;
        text-decoration:none;
      }
    }
    p {
      display: flex;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      margin-bottom: 31px;
      span {
        margin-right: 22px;
        &:first-of-type {
          width: 84px!important;
          display: block;
          text-align: left!important;
        }
      }
    }
  }
}
</style>

<style lang="scss">

.Enterprise, .Enterprise-confirm, .Enterprise-confirm-type_001{
  .el-radio{
    width: 200px;
    .el-radio__input{
      width: 30px;
      margin: 0px !important;
      line-height: 40px;
    }
  }
}

</style>>